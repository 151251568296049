<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>
                  <span> Finance</span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Finance

              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                <div class="">
                </div>

              </div>
            </div>
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
<!--                    <form class="d-flex flex-center py-2 px-6 bg-white rounded">-->
<!--														<span class="svg-icon svg-icon-lg svg-icon-primary">-->
<!--															&lt;!&ndash;begin::Svg Icon | path:/metronic/theme/html/demo5/dist/assets/media/svg/icons/General/Search.svg&ndash;&gt;-->
<!--															<i class="fas fa-search"></i>-->
<!--                              &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
<!--														</span>-->
<!--                      <v-text-field-->
<!--                          dense-->
<!--                          flat-->
<!--                          solo-->
<!--                          lass="form-control border-0 font-weight-bold pl-2"-->
<!--                          type="text"-->

<!--                          label="Search By Name, Email or Phone"-->
<!--                          placeholder="Search By Unique No, Name, Email or Phone"-->
<!--                      ></v-text-field>-->
<!--                      &lt;!&ndash;                          <input type="text" class="form-control border-0 font-weight-bold pl-2" placeholder="Search Goods">&ndash;&gt;-->
<!--                    </form>-->

                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-list two-line outlined v-if="stats && stats.recent_transaction">
                  <v-subheader>Recent transactions</v-subheader>
                  <v-list-item-group
                      active-class="pink--text"
                      multiple
                  >
                    <template v-for="(item, index) in stats.recent_transaction">
                      <v-list-item :key="index">
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title v-text="item.payer_name"></v-list-item-title>

                            <v-list-item-subtitle
                                class="text--primary"
                                v-text="item.payer_mobile"
                            ></v-list-item-subtitle>

                            <v-list-item-subtitle>Rs. {{item.paid_amount}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <!--                        <v-list-item-action-text v-text="item.action"></v-list-item-action-text>-->
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                          v-if="index < stats.recent_transaction[0].length - 1"
                          :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="8">
                <v-list two-line outlined>
                  <v-list-item-group
                      active-class="pink--text"
                      multiple
                  >
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title class="headline text-uppercase"> Income
                          </v-list-item-title>

                          <v-list-item-subtitle
                              class="text--primary title"
                          > Rs. {{stats && stats.today_income?stats.today_income:'00'}}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle>{{stats.today}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-sheet

                          >
                            <!--                        <pre>{{stats.latest_income}}</pre>-->
                            <v-sparkline v-if="stats && stats.latest_income"
                                         :value="stats.latest_income"
                                         color="green"
                                         line-width="2"
                                         padding="16"
                            ></v-sparkline>
                          </v-sheet>
                        </v-list-item-content>

                        <!--                                            <v-list-item-action>-->
                        <!--                                                <v-list-item-action-text >5 May 2021</v-list-item-action-text>-->
                        <!--                                            </v-list-item-action>-->
                      </template>
                    </v-list-item>

                    <v-divider
                    ></v-divider>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title class="headline text-uppercase"> Expense
                          </v-list-item-title>

                          <v-list-item-subtitle
                              class="text--primary title"
                          > Rs. {{stats && stats.today_expense?stats.today_expense:'00'}}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle>{{stats.today}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-sheet

                          >
                            <v-sparkline v-if="stats && stats.latest_expense"
                                         :value="stats.latest_expense"
                                         color="red"
                                         line-width="2"
                                         padding="16"
                            ></v-sparkline>
                          </v-sheet>
                        </v-list-item-content>

                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";

const dashboardService = new DashboardService();
export default {
  name: "FinanceDashboard",
  data: () => ({
    selected: [2],
    stats: []
  }),
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      dashboardService.getFinanceStat().then(response => {
        this.stats = response.data
      })
    }
  }
}
</script>

<style scoped>

</style>